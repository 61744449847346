<script>
import { createMerchant } from '@/utills/api';
import Preloader from '@/components/Preloader.vue';
import BackpageButtonVue from '@/components/BackpageButton.vue';
import CreateFormVue from '@/components/CreateForm.vue';

export default {
  name: 'CreateMerchantVue',
  components: { CreateFormVue, BackpageButtonVue, Preloader },

  data: () => {
    return {
      preloaderActive: false,
      preloaderComplete: false,
    };
  },

  computed: {
    formOptions() {
      return {
        title: 'Создание мерчанта',
        submitText: 'Создать мерчанта',
        fields: [
          { name: 'Имя мерчанта', type: 'text', model: 'name' },
          { name: 'Адрес', type: 'text', model: 'address' },
          { name: 'BIN', type: 'number', model: 'bin' },
          { name: 'Владелец', type: 'text', model: 'owner' },
          { name: 'Номер контракта', type: 'text', model: 'contractNumber' },
        ],
      };
    },
  },

  methods: {
    merchantCreateHandler(data) {
      this.preloaderActive = true;
      createMerchant(data, this.$keycloak.getToken()).then((res) => {
        res.success ? (this.preloaderComplete = true) : '';
        // setTimeout(() => {
        //   this.$router.push('create-merchant');
        // }, 4000);
      });
    },
  },
};
</script>

<template>
  <section class="create-merchant">
    <BackpageButtonVue />
    <Preloader v-if="preloaderActive" :preloaderComplete="preloaderComplete" />
    <CreateFormVue v-else :formOptions="formOptions" :createHandler="merchantCreateHandler" />
  </section>
</template>

<style scoped>
.create-merchant {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 20px;
}
</style>
