<script>
import { getAllWallets } from '@/utills/api';
import DataTable from './DataTable.vue';
import WalletsList from './WalletsList.vue';

import Preloader from './Preloader.vue';
import TableFilters from './TableFilters.vue';

export default {
  name: 'WalletsVue',

  props: { approvedRoles: Array },

  components: { WalletsList, DataTable, Preloader, TableFilters },

  mounted() {
    this.$keycloak.hasRole(this.approvedRoles)
      ? this.getWalletsData(0, 11)
      : this.$router.push('/');
  },

  data: () => {
    return {
      walletsInfo: {
        tableData: [],
        tableKey: 'wallets',
        currentPage: '',
        totalPages: '',
        totalRows: '',
        visibleRows: 11,
        pageDisabled: { first: true, last: true },
        exportActive: true,
        filters: {},
      },

      filtersList: {
        selectFilters: [
          {
            filterName: 'isActive',
            options: [
              {
                name: 'Статус',
                value: '',
              },
              {
                name: 'Активный',
                value: 'true',
              },
              {
                name: 'Не активный',
                value: 'false',
              },
            ],
          },
          {
            filterName: 'type',
            options: [
              {
                name: 'Тип кошелька',
                value: '',
              },
              {
                name: 'Оператор',
                value: 'OPERATOR',
              },
              {
                name: 'Мерчант',
                value: 'MERCHANT',
              },
              {
                name: 'Агент',
                value: 'AGENT',
              },
            ],
          },
        ],
        selectInputFilters: [],
      },

      orient: 'grid',
      preloaderActive: false,
      componentAccess: null,
    };
  },

  methods: {
    getWalletsData(page, size) {
      this.preloaderActive = true;
      getAllWallets(page, size, this.walletsInfo.filters, this.$keycloak.getToken())
        .then((res) => {
          this.walletsInfo.tableData = res.message.content;
          this.walletsInfo.totalRows = res.message.content.length;
          this.walletsInfo.currentPage = res.message.pageable.pageNumber;
          this.walletsInfo.totalPages = res.message.totalPages;
          this.walletsInfo.pageDisabled.first = res.message.first;
          this.walletsInfo.pageDisabled.last = res.message.last;
          this.preloaderActive = false;
        })
        .catch((err) => {
          this.$store.commit('setAppErrors', {
            active: true,
            statusCode: err,
            text: 'Произошла ошибка сервера',
          });
          this.$router.push('errors');
        });
    },

    walletsDataFilter(filters) {
      this.walletsInfo.filters = filters;
      this.getWalletsData(0, this.orient === 'grid' ? this.visibleRows : 6);
    },

    selectRows(evt) {
      this.visibleRows = evt.target.value;
      this.getWalletsData(0, this.visibleRows);
    },

    orientHandler(evt) {
      this.orient = evt.currentTarget.id;
      this.orient === 'grid' ? this.getWalletsData(0, this.visibleRows) : this.getWalletsData(0, 6);
    },
  },
};
</script>

<template>
  <section class="wallets">
    <div class="wallets__orient">
      <button @click="orientHandler" id="tile" class="wallets__orient-button">
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.75 3C3.88235 3 3 3.88235 3 6.75C3 9.61765 3.88235 10.5 6.75 10.5C9.61765 10.5 10.5 9.61765 10.5 6.75C10.5 3.88235 9.61765 3 6.75 3Z"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round" />
          <path
            d="M6.75 13.5C3.88235 13.5 3 14.3824 3 17.25C3 20.1176 3.88235 21 6.75 21C9.61765 21 10.5 20.1176 10.5 17.25C10.5 14.3824 9.61765 13.5 6.75 13.5Z"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round" />
          <path
            d="M17.25 13.5C14.3824 13.5 13.5 14.3824 13.5 17.25C13.5 20.1176 14.3824 21 17.25 21C20.1176 21 21 20.1176 21 17.25C21 14.3824 20.1176 13.5 17.25 13.5Z"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round" />
          <path
            d="M17.25 3C14.3824 3 13.5 3.88235 13.5 6.75C13.5 9.61765 14.3824 10.5 17.25 10.5C20.1176 10.5 21 9.61765 21 6.75C21 3.88235 20.1176 3 17.25 3Z"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </button>
      <button @click="orientHandler" id="grid" class="wallets__orient-button">
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="Edit / Table">
            <path
              id="Vector"
              d="M4 15V16.8002C4 17.9203 4 18.4801 4.21799 18.9079C4.40973 19.2842 4.71547 19.5905 5.0918 19.7822C5.5192 20 6.07899 20 7.19691 20H12M4 15V9M4 15H12M4 9V7.2002C4 6.08009 4 5.51962 4.21799 5.0918C4.40973 4.71547 4.71547 4.40973 5.0918 4.21799C5.51962 4 6.08009 4 7.2002 4H12M4 9H12M12 4H16.8002C17.9203 4 18.4801 4 18.9079 4.21799C19.2842 4.40973 19.5905 4.71547 19.7822 5.0918C20 5.5192 20 6.07899 20 7.19691V9M12 4V9M12 9V15M12 9H20M12 15V20M12 15H20M12 20H16.8036C17.9215 20 18.4805 20 18.9079 19.7822C19.2842 19.5905 19.5905 19.2842 19.7822 18.9079C20 18.4805 20 17.9215 20 16.8036V15M20 15V9"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round" />
          </g>
        </svg>
      </button>
    </div>
    <TableFilters
      :selectRows="orient === 'grid' ? selectRows : null"
      :filtersList="filtersList"
      :filterMethod="walletsDataFilter" />

    <Preloader v-if="preloaderActive" />
    <DataTable
      v-if="orient === 'grid' && !preloaderActive"
      :tableInfo="walletsInfo"
      :paginationMethod="getWalletsData"
      :tableSize="{ height: '600px', width: '100%', flex: 1 }"></DataTable>
    <WalletsList
      v-if="orient === 'tile' && !preloaderActive"
      :listInfo="{
        walletsData: walletsInfo.tableData,
        totalPages: walletsInfo.totalPages,
        currentPage: walletsInfo.currentPage,
        pageDisabled: walletsInfo.pageDisabled,
      }"
      :paginationMethod="getWalletsData" />
  </section>
</template>

<style scoped>
.wallets {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.wallets__orient {
  max-width: 200px;
  width: 100%;
  display: flex;
  align-self: flex-start;
  gap: 15px;
}

.sun .wallets__orient-button {
  color: #060b27;
  border: 1px solid #060b27;
}

.wallets__orient-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e9e7e7;
  border-radius: 5px;
  border: 1px solid #e9e7e7;
  transition: 0.3s;
  padding: 10px;
}

.wallets__orient-button svg {
  width: 20px;
  height: 20px;
}

.wallets__orient-button:hover {
  background-color: #2196f3;
  color: #fff;
}

.sun .wallets__orient-button:hover {
  background-color: #060b27;
  color: #fff;
}
</style>
