<script>
export default {
  name: 'WalletOptionVue',

  props: { walletStyle: Object, walletMethodId: Number, closeOption: Function },

  computed: {
    walletMethod() {
      const walletMethod = {
        1: { title: 'Внести', subtitle: 'ADD BALANCE', color: '#08a835' },
        2: { title: 'Снять', subtitle: 'WITHDRAW BALANCE', color: 'red' },
        3: { title: 'Перевести', subtitle: 'TRANSFER BALANCE', color: 'orange' },
      };
      return walletMethod[this.walletMethodId];
    },
  },

  data: () => {
    return { walletOperationActive: false };
  },

  methods: {
    openWalletOperation() {
      this.walletOperationActive = !this.walletOperationActive;
      this.closeOption(this.walletMethodId);
    },
  },
};
</script>

<template>
  <div class="wallet__options-container">
    <p class="wallet__options-text" :style="{ 'border-color': walletStyle[2] }">
      {{ walletMethod.title }}
      <button
        @click="openWalletOperation"
        class="wallets__more-button"
        :class="{ 'button-rotate': walletOperationActive }"
        :style="{ color: walletStyle[1] }">
        <svg
          fill="none"
          stroke="currentColor"
          stroke-width="1.5"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5"></path>
        </svg>
      </button>
    </p>
    <form
      class="wallet__operation-form"
      :class="{ wallet__operation_active: walletOperationActive }">
      <div class="wallet__operation-container">
        <p class="wallet__operation-title">{{ walletMethod.subtitle }}</p>
        <label class="wallet__operation-label">
          <input placeholder="Введите сумму" class="wallet__operation-input" />
          <button
            type="submit"
            class="wallet__operation-submit"
            :style="{ 'background-color': walletMethod.color }"></button>
        </label>
        <!-- <p class="wallet__operation-title">Комментарий (Необязательно)</p>
        <label class="wallet__operation-label">
          <textarea
            type="text"
            placeholder="Введите комментарий"
            class="wallet__operation-input"></textarea>
        </label> -->
      </div>
    </form>
  </div>
</template>

<style>
.wallet__options-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.wallet__options-text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 700;
  border-top: 1px solid;
  padding: 8px 20px;
}

.wallet__operation-form {
  width: 100%;
  max-height: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: 0.5s;
}

.wallet__operation-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 16px;
}

.wallet__operation-title {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: #212529;
  opacity: 0.4;
}

.wallet__operation-label {
  display: flex;
  align-items: center;
  gap: 8px;
}

.wallet__operation-label textarea {
  min-height: 150px;
  resize: none;
  outline: none;
  padding-top: 20px;
}

.wallet__operation-input {
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  border-radius: 10px;
  border: 1px solid black;
  padding: 8px;
}

.wallet__operation-input::placeholder {
  color: #a5a7a8;
  font-weight: 600;
}

.wallet__operation-submit {
  max-width: 40px;
  width: 100%;
  height: 100%;
  min-height: 40px;
  background-image: url(@/assets/icons/pay.svg);
  background-color: #08a835;
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  transition: 0.3s;
}

.wallet__operation-submit:hover {
  transform: scale(1.2);
}

.wallet__operation_active {
  max-height: 300px;
}

.button-rotate {
  rotate: 90deg;
}
</style>
