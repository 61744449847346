<script>
export default {
  name: 'CreateFormVue',

  props: { createHandler: Function, formOptions: Object },

  computed: {
    formFields() {
      return this.formOptions.fields;
    },
  },

  data: () => {
    return { formSubmitFields: {} };
  },

  methods: {
    formSubmit() {
      this.createHandler(this.formSubmitFields);
    },
  },
};
</script>

<template>
  <form class="create__form" @submit.prevent="formSubmit">
    <p class="form-step">{{ formOptions.title }}</p>
    <div class="form-container">
      <label
        v-for="(field, index) in formFields"
        class="create__label"
        :for="field.model"
        :key="index">
        <p class="label-title">{{ field.name }}</p>
        <input
          class="create__input"
          :id="field.model"
          :type="field.type"
          required
          v-model="formSubmitFields[field.model]" />
        <span class="input-error__text"></span>
      </label>
    </div>
    <button class="form-submit" type="submit" required>{{ formOptions.submitText }}</button>
  </form>
</template>

<style scoped>
.create__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  background: #44454d;
  border-radius: 15px;
  padding: 90px 30px;
}

.form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.form-container_direction_row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.create__label-container {
  display: flex;
  justify-content: space-between;
}

.form-step {
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}

.sun .create__form {
  background: #f5f6fa;
}

.create__label {
  max-width: 400px;
  width: 100%;
}

.label-title {
  font-size: 16px;
  font-weight: 500;
  opacity: 0.7;
  margin-bottom: 10px;
}

.create__input {
  width: 100%;
  background: #fff;
  border-radius: 12px;
  padding: 8px 10px;
}

.create__input::placeholder {
  font-size: 12px;
}

.input-error__text {
}

.form-submit {
  width: 400px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
  color: #e9e7e7;
  border: 1px solid #e9e7e7;
  text-decoration: none;
  transition: 0.5s ease-in-out;
  padding: 15px;
  margin-top: 20px;
}

.form-submit:hover {
  background-color: #2196f3;
  color: #fff;
}

.sun .form-submit {
  background: transparent;
  color: #060b27;
  border: 1px solid #060b27;
}

.sun .form-submit:hover {
  background-color: #060b27;
  color: #fff;
}
</style>
