<script>
export default {
  name: 'ErrorVue',

  computed: {
    errorObj() {
      return this.$store.state.appErrors;
    },
  },

  mounted() {
    this.errorObj.active ? '' : this.$router.push('/');
  },

  unmounted() {
    this.$store.commit('setAppErrors', { active: false });
  },

  data: () => {
    return {};
  },

  methods: {
    goHome() {
      this.$router.push('/');
    },
  },
};
</script>

<template>
  <section class="error">
    <div class="error__server">
      <p class="error__server-number">{{ this.$store.state.appErrors.statusCode }}</p>
      <p class="error__server-text">{{ this.$store.state.appErrors.text }}</p>
    </div>
    <button @click="goHome" class="error__home-button">На главную</button>
  </section>
</template>

<style>
.error {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 70px;
  padding: 100px 0;
}

.error__server {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.error__server-number {
  text-align: center;
  font-size: 150px;
  font-weight: 600;
}

.error__server-text {
  font-size: 45px;
}

.error__home-button {
  max-width: 200px;
  width: 100%;
  color: currentColor;
  font-size: 22px;
  font-weight: 500;
  background-color: #060b27;
  border-radius: 5px;
  padding: 15px;
  transition: 0.3s;
}

.error__home-button:hover {
  background: #fff;
  color: #060b27;
}

.sun .error__home-button:hover {
  background: #060b27;
  color: #fff;
}

.sun .error__home-button {
  background-color: #f5f6fa;
}
</style>