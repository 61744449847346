<script>
import Navigation from '@/components/Navigation.vue';
import Header from '@/components/Header.vue';

export default {
  name: 'HomeView',

  components: { Navigation, Header },

  mounted() {
    localStorage.darkmode ? (this.pageTheme.dark = true) : '';
  },

  data: () => {
    return {
      pageTheme: { dark: false },
    };
  },

  methods: {
    switchTheme() {
      this.pageTheme.dark = !this.pageTheme.dark;
      localStorage.darkmode
        ? localStorage.removeItem('darkmode')
        : localStorage.setItem('darkmode', 'true');
    },
  },
};
</script>

<template>
  <div class="page" :class="{ sun: !pageTheme.dark }">
    <Navigation />
    <div class="page__container">
      <Header :pageTheme="pageTheme" :switchTheme="switchTheme"></Header>
      <main class="content">
        <RouterView :key="$route.path"></RouterView>
      </main>
    </div>
  </div>
</template>

<style scoped>
.page {
  width: 100%;
  display: flex;
  width: 100%;
  min-height: 100vh;
  background: #060b27;
  color: #fff;
  margin: 0;
  padding-bottom: 20px;
  transition: 0.3s;
}

.sun {
  background: #f5f6fa;
  color: #060b27;
}

.page__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  color: currentColor;
  padding: 0 30px;
}

.sun .content {
  background: #fff;
}

.content {
  position: relative;
  width: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  background: #242630;
  overflow: hidden;
  border-radius: 5px;
  padding: 15px;
}
</style>
