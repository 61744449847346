<script>
export default {
  name: 'PopupVue',

  props: {
    closePop: Function,
    popupHandler: Function,
  },

  methods: {
    popupButtonHandler() {
      this.popupHandler();
    },
  },
};
</script>

<template>
  <div class="popup">
    <div class="popup__container">
      <button @click="closePop" type="button" class="popup__close-button"></button>
      <slot></slot>
    </div>
  </div>
</template>

<style>
.popup {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s linear;
  z-index: 5000;
}

.popup__container {
  position: relative;
  max-width: 400px;
  width: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 30px;
  background: #242630;
  border-radius: 30px;
  padding: 60px;
}

.popup__title {
  font-size: 18px;
  font-weight: 600;
}

.popup__subtitle {
  font-size: 14px;
  font-weight: 600;
}

.popup__subtitle_red {
  color: #f03e3e;
}

.sun .popup {
  background: rgba(0, 0, 0, 0.5);
}

.sun .popup__container {
  background: #f5f6fa;
}

.popup__close-button {
  position: absolute;

  top: -20px;
  right: -25px;
  max-width: 30px;
  width: 100%;
  background: url(@/assets/icons/closePop.svg);
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  padding: 15px 0;
}
</style>
