<script>
import WalletsOption from './WalletsOption.vue';

export default {
  name: 'WalletsItem',

  props: {
    wallet: Object,
  },

  components: { WalletsOption },

  computed: {
    walletStyle() {
      const walletColors = {
        paygate: { 1: '#6fcf97', 2: '#4a3dab', 3: '#fff' },
        kcell: { 1: '#652d86', 2: '#652d86', 3: '#fff' },
        beeline: { 1: '#000', 2: '#ffd300', 3: '#000' },
      };
      if (walletColors[this.wallet.walletName.toLowerCase()] && this.wallet.isActive) {
        return walletColors[this.wallet.walletName.toLowerCase()];
      } else if (!this.wallet.isActive) {
        return { 1: '#212529', 2: 'red', 3: '#fff' };
      } else {
        return { 1: '#212529', 2: '#212529', 3: '#fff' };
      }
    },
  },

  data: () => {
    return { walletsOptionsActive: false, walletOperationActive: false };
  },

  methods: {
    openWalletsOptions() {
      this.walletsOptionsActive = !this.walletsOptionsActive;
      this.closeOption(0);
    },

    closeOption(id) {
      Object.values(this.$refs).forEach((item, index) => {
        index + 1 !== id ? (item.$data.walletOperationActive = false) : '';
      });
    },
  },
};
</script>

<template>
  <li
    class="wallets__item"
    :style="{ color: walletStyle[1], border: `0.5px solid ${walletStyle[2]}` }">
    <div
      class="wallets__item-plague"
      :style="{ color: walletStyle[3], background: walletStyle[2] }">
      <p class="wallets__plague-text">Кошелёк</p>
      <svg
        class="-rotate-90"
        height="30px"
        width="30px"
        viewBox="0 0 26 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.9634 3.1877C16.1356 2.97039 16.4516 2.9346 16.668 3.1079L17.7487 3.97351L13.7982 9.00004H16.3419L19.3097 5.22389L21.8142 7.22997C22.0353 7.40702 22.0653 7.73213 21.8804 7.94665L20.9728 8.99976H23.5869C24.2916 7.93783 24.0861 6.48718 23.0646 5.66898L17.9183 1.54691C16.8365 0.680403 15.2562 0.859399 14.3957 1.94591L8.80859 8.99976H11.36L15.9634 3.1877ZM18 18C17.4477 18 17 18.4477 17 19C17 19.5523 17.4477 20 18 20H21C21.5523 20 22 19.5523 22 19C22 18.4477 21.5523 18 21 18H18ZM3 6C1.34315 6 0 7.34315 0 9V23.5004C0 25.9857 2.01472 28.0004 4.5 28.0004H21.5C23.9853 28.0004 26 25.9857 26 23.5004V14.5004C26 12.0152 23.9853 10.0004 21.5 10.0004L3 10C2.44772 10 2 9.55228 2 9C2 8.44772 2.44772 8 3 8H7.58L9.17625 6H3ZM2 23.5004V11.8293C2.31278 11.9398 2.64936 12 3 12H6.46974C6.47979 12.0003 6.48988 12.0004 6.5 12.0004H21.5C22.8807 12.0004 24 13.1197 24 14.5004V23.5004C24 24.8812 22.8807 26.0004 21.5 26.0004H4.5C3.11929 26.0004 2 24.8812 2 23.5004Z"
          fill="currentColor"></path>
      </svg>
    </div>
    <div class="wallets__item-description">
      <div class="wallets__item-head">
        <h4 class="wallets__item-title">{{ wallet.walletName }}</h4>
        <p
          class="wallets__item-role"
          :style="{ color: walletStyle[3], background: walletStyle[2] }">
          {{ wallet.type }}
        </p>
      </div>
      <div class="wallets__item-footer">
        <div class="wallets__quantity">
          <p class="wallets__quantity-text">
            {{ wallet.moneyAmount }} <span class="quantity-text_currency">KZT</span>
          </p>
          <!-- <button
            @click="openWalletsOptions"
            class="wallets__more-button"
            :class="{ 'button-rotate': walletsOptionsActive }"
            :style="{ color: walletStyle[1] }">
            <svg
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"></path>
            </svg>
          </button> -->
        </div>

        <div class="wallets__options" :class="{ wallets__options_active: walletsOptionsActive }">
          <WalletsOption
            :walletStyle="walletStyle"
            :closeOption="closeOption"
            :walletMethodId="1"
            ref="1" />
          <WalletsOption
            :walletStyle="walletStyle"
            :closeOption="closeOption"
            :walletMethodId="2"
            ref="2" />
          <WalletsOption
            :walletStyle="walletStyle"
            :closeOption="closeOption"
            :walletMethodId="3"
            ref="3" />
        </div>
      </div>
    </div>
    <!-- <button type="button" class="wallets__item-info">
      <svg
        fill="none"
        stroke="currentColor"
        stroke-width="1.8"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"></path>
      </svg>
    </button> -->
  </li>
</template>

<style>
.wallets__item {
  position: relative;
  max-width: 470px;
  width: 100%;
  min-height: 280px;
  display: flex;
  justify-content: space-between;
  border-radius: 30px;
  overflow: hidden;
  transition: 0.3s linear;
}

.wallets__item:hover {
  transform: scale(1.03);
}

.wallets__item-info {
  max-width: 30px;
  width: 100%;
  position: absolute;
  top: 20px;
  right: 20px;
  transition: 0.3s;
}

.wallets__item-info:hover {
  transform: scale(1.1);
}

.wallets__item-plague {
  width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 20px;
  padding: 30px 10px;
}
.wallets__plague-text {
  font-size: 25px;
  font-weight: 700;
  line-height: 35px;
  transform: rotate(-90deg);
}

.wallets__item-plague svg {
  max-width: 30px;
  transform: rotate(-90deg);
  margin: 0 5px;
}

.wallets__item-description {
  width: 85%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 94px;
  background-color: #fff;
  padding: 30px 0 50px 0;
}

.wallets__item-head {
  display: flex;
  flex-direction: column;
  gap: 9px;
  padding: 0 20px;
}
.wallets__item-title {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 600;
}
.wallets__item-role {
  width: fit-content;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 10px;
  background: #fff;
  padding: 8px;
}

.wallets__quantity {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}
.wallets__quantity-text {
  font-size: 36px;
  font-weight: 700;
}
.quantity-text_currency {
  font-size: 14px;
}
.wallets__more-button {
  max-width: 40px;
  width: 100%;
  height: 40px;
  border-radius: 50%;
  transition: 0.3s;
}

.wallets__more-button:hover {
  background: #e0e0e0;
}

.wallets__more-button svg {
  display: block;
  width: 24px;
  stroke-width: 4px;
  margin: auto;
}

.wallets__options {
  width: 100%;
  max-height: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  opacity: 0;
  overflow: hidden;
  transition: 0.2s ease-in-out;
}

.wallets__options_active {
  max-height: 700px;
  opacity: 1;
  padding-top: 20px;
}

.button-rotate {
  rotate: 90deg;
}
</style>
