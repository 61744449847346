<script>
import Preloader from '@/components/Preloader.vue';
import BackpageButtonVue from '@/components/BackpageButton.vue';
import { createFee } from '@/utills/api';
export default {
  name: 'CreateFeesVue',
  components: { BackpageButtonVue, Preloader },

  mounted() {
    this.$store.state.serviceData.id
      ? (this.submitFields.serviceId = this.$store.state.serviceData.id)
      : this.$router.push('merchants');
  },

  computed: {
    feesCorrect() {
      let feesValues = {
        low: this.submitFields.low.value,
        high: this.submitFields.high.value,
        extra: this.submitFields.extra.value,
        service: this.submitFields.service.value,
      };

      let feesSum = feesValues.low + feesValues.extra + feesValues.service;

      return feesSum === 100
        ? { correct: true, feesSum: feesSum }
        : { correct: false, feesSum: feesSum };
    },
  },

  watch: {
    'submitFields.low': {
      handler(val) {
        this.errorObject.message = null;
        val.value = val.operator + val.agent;
      },
      deep: true,
    },
    'submitFields.high': {
      handler(val) {
        this.errorObject.message = null;
        val.value = val.operator + val.agent;
      },
      deep: true,
    },
    'submitFields.service': {
      handler(val) {
        this.errorObject.message = null;
        val.value = val.service;
      },
      deep: true,
    },
  },

  data: () => {
    return {
      preloaderActive: false,
      preloaderComplete: false,
      errorObject: { message: null },
      submitFields: {
        serviceId: null,
        agentId: null,
        low: { operator: null, agent: null, value: null },
        high: { operator: null, agent: null, value: null },
        extra: { operator: 100, agent: 0, value: null },
        service: { service: null, value: null },
      },
    };
  },

  methods: {
    feesCreateHandler() {
      let feesData = {
        serviceId: this.submitFields.serviceId,
        agentId: this.submitFields.agentId,
        low: {
          operator: Number(this.submitFields.low.operator).toFixed(2),
          agent: Number(this.submitFields.low.agent).toFixed(2),
          value: Number(this.submitFields.low.value).toFixed(2),
        },
        high: {
          operator: Number(this.submitFields.high.operator).toFixed(2),
          agent: Number(this.submitFields.high.agent).toFixed(2),
          value: Number(this.submitFields.high.value).toFixed(2),
        },
        extra: {
          operator: Number(this.submitFields.extra.operator).toFixed(2),
          agent: Number(this.submitFields.extra.agent).toFixed(2),
          value: Number(this.submitFields.extra.value).toFixed(2),
        },
        service: {
          service: Number(this.submitFields.service.service).toFixed(2),
          value: Number(this.submitFields.service.value).toFixed(2),
        },
      };
      this.preloaderActive = true;
      this.errorObject.message = null;
      createFee(feesData, this.$keycloak.getToken()).then((res) => {
        if (res.success) {
          this.preloaderComplete = true;
        } else {
          this.preloaderActive = false;
          this.errorObject.message = res.message;
        }
      });
    },
  },
};
</script>

<template>
  <section class="create-merchant">
    <BackpageButtonVue />
    <Preloader
      v-if="preloaderActive && !errorObject.message"
      :preloaderComplete="preloaderComplete" />

    <form v-else @submit.prevent="feesCreateHandler" class="create__form">
      <p class="form-step">Создание комисий</p>

      <div class="form-container">
        <label class="create__label">
          <p class="label-title">Агент</p>
          <div class="create__label-container">
            <select class="create__input" required v-model="submitFields.agentId">
              <option value="e37dd266-bc75-4a89-beed-9c6fa5f2c885">Kcell</option>
              <option value="e37dd266-bc75-4a89-beed-9c6fa5f2c885">Kcell</option>
              <option value="e37dd266-bc75-4a89-beed-9c6fa5f2c885">Kcell</option>
              <option value="e37dd266-bc75-4a89-beed-9c6fa5f2c885">Kcell</option>
            </select>
          </div>
        </label>
        <label class="create__label">
          <p class="label-title">Нижняя комиссия</p>
          <div class="form-container_direction_row">
            <div class="form-container__input">
              <input
                type="number"
                min="0"
                step="0.01"
                placeholder="0"
                class="create__input"
                v-model.number="submitFields.low.operator"
                required />
              <p class="input-title">Доля оператора</p>
            </div>
            <div class="form-container__input">
              <input
                type="number"
                min="0"
                step="0.01"
                placeholder="0"
                class="create__input"
                v-model.number="submitFields.low.agent"
                required />
              <p class="input-title">Доля агента</p>
            </div>
            <div class="form-container__input">
              <input
                type="number"
                min="0"
                step="0.01"
                placeholder="0"
                class="create__input"
                v-model="submitFields.low.value"
                required
                readonly />
              <p class="input-title">Размер комисии</p>
            </div>
          </div>
        </label>
        <label class="create__label">
          <p class="label-title">Верхняя комиссия</p>
          <div class="form-container_direction_row">
            <div class="form-container__input">
              <input
                type="number"
                min="0"
                step="0.01"
                placeholder="0"
                class="create__input"
                v-model.number="submitFields.high.operator"
                required />
              <p class="input-title">Доля оператора</p>
            </div>
            <div class="form-container__input">
              <input
                type="number"
                min="0"
                step="0.01"
                placeholder="0"
                class="create__input"
                v-model.number="submitFields.high.agent"
                required />
              <p class="input-title">Доля агента</p>
            </div>
            <div class="form-container__input">
              <input
                type="number"
                min="0"
                step="0.01"
                placeholder="0"
                class="create__input"
                v-model.number="submitFields.high.value"
                required
                readonly />
              <p class="input-title">Размер комисии</p>
            </div>
          </div>
        </label>
        <label class="create__label">
          <p class="label-title">Экстра комиссия</p>
          <div class="form-container_direction_row">
            <div class="form-container__input">
              <input
                type="number"
                min="0"
                step="0.01"
                placeholder="0"
                class="create__input"
                v-model.number="submitFields.extra.operator"
                required
                readonly />
              <p class="input-title">Доля оператора</p>
            </div>
            <div class="form-container__input">
              <input
                type="number"
                min="0"
                step="0.01"
                placeholder="0"
                class="create__input"
                v-model.number="submitFields.extra.agent"
                required
                readonly />
              <p class="input-title">Доля агента</p>
            </div>
            <div class="form-container__input">
              <input
                type="number"
                min="0"
                step="0.01"
                placeholder="0"
                class="create__input"
                v-model.number="submitFields.extra.value"
                required />
              <p class="input-title">Размер комисии</p>
            </div>
          </div>
        </label>
        <label class="create__label">
          <p class="label-title">Комиссия сервиса</p>
          <div class="form-container_direction_row">
            <div class="form-container__input">
              <input
                type="number"
                min="0"
                step="0.01"
                placeholder="0"
                class="create__input"
                v-model.number="submitFields.service.service"
                required />
              <p class="input-title">Доля сервиса</p>
            </div>

            <div class="form-container__input">
              <input
                type="number"
                min="0"
                step="0.01"
                placeholder="0"
                class="create__input"
                v-model.number="submitFields.service.value"
                required
                readonly />
              <p class="input-title">Размер комисии</p>
            </div>
          </div>
        </label>
      </div>
      <p v-if="!feesCorrect.correct" class="form-error-text">
        Сумма комиссий должна составлять 100 сейчас: {{ feesCorrect.feesSum }}
      </p>
      <p v-if="errorObject.message" class="form-error-text">{{ errorObject.message }}</p>
      <button class="form-submit" type="submit" :disabled="!feesCorrect.correct" required>
        Создать комисии
      </button>
    </form>
  </section>
</template>

<style scoped>
.create-merchant {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 20px;
}

.create__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  background: #44454d;
  border-radius: 15px;
  padding: 40px 30px;
}

.form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.form-container_direction_row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.create__label-container {
  display: flex;
  justify-content: space-between;
}

.form-step {
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}

.sun .create__form {
  background: #f5f6fa;
}

.create__label {
  max-width: 400px;
  width: 100%;
}

.label-title {
  font-size: 16px;
  font-weight: 500;
  opacity: 0.7;
  margin-bottom: 10px;
}

.input-title {
  font-size: 12px;
  font-weight: 500;
  opacity: 0.7;
  margin-top: 5px;
}

.create__input {
  width: 100%;
  background: #fff;
  border-radius: 12px;
  padding: 8px 10px;
}

.create__input::placeholder {
  font-size: 12px;
}

.input-error__text {
}

.form-submit {
  width: 400px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
  color: #e9e7e7;
  border: 1px solid #e9e7e7;
  text-decoration: none;
  transition: 0.5s ease-in-out;
  padding: 15px;
  margin-top: 20px;
}

.form-submit:disabled {
  opacity: 0.3;
  pointer-events: none;
}

.form-submit:hover {
  background-color: #2196f3;
  color: #fff;
}

.form-error-text {
  color: #fb3f1d;
  font-size: 12px;
  font-weight: 600;
}

.sun .form-submit {
  background: transparent;
  color: #060b27;
  border: 1px solid #060b27;
}

.sun .form-submit:hover {
  background-color: #060b27;
  color: #fff;
}
</style>
