<script>
import NavigationItem from './NavigationItem.vue';

export default {
  name: 'NavigationListVue',

  components: { NavigationItem },

  computed: {
    navigationList() {
      return [
        {
          title: 'История транзакций',
          icon: this.$store.state.navigationIcons.tableIcon,
          path: '/transaction-history',
          approvedRoles: ['ROLE_ADMIN', 'ROLE_ACCOUNTANT'],
        },
        {
          title: 'Кошельки',
          icon: this.$store.state.navigationIcons.walletIcon,
          path: '/wallets',
          approvedRoles: ['ROLE_ADMIN', 'ROLE_ACCOUNTANT'],
        },
        {
          title: 'Список Мерчантов',
          icon: this.$store.state.navigationIcons.merchantsIcon,
          path: '/merchants',
          approvedRoles: ['ROLE_ADMIN', 'ROLE_ACCOUNTANT'],
        },
        {
          title: 'Подписки',
          icon: this.$store.state.navigationIcons.subscribeIcon,
          path: '/subscribers',
          approvedRoles: ['ROLE_ADMIN', 'ROLE_ACCOUNTANT'],
        },
      ];
    },
  },

  data: () => {
    return {};
  },
};
</script>

<template>
  <nav class="navigation__list">
    <RouterLink class="navigation__list-header" to="/">
      <span class="navigation__list-logo"></span>
      <p class="navigation__list-title">PayGate</p>
    </RouterLink>

    <NavigationItem
      v-for="(item, index) in navigationList"
      :key="index"
      :title="item.title"
      :path="item.path"
      :access="$keycloak.hasRole(item.approvedRoles)">
      <span class="navigation__item-icon" v-html="item.icon"></span>
    </NavigationItem>
  </nav>
</template>

<style scoped>
.navigation__list {
  position: fixed;
  top: 75px;
  max-width: 230px;
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;

  gap: 20px;
  background: linear-gradient(
    127.09deg,
    rgba(111, 207, 151, 0.94) 19.41%,
    rgba(111, 207, 151, 0.49) 76.65%
  );
  border-radius: 5px;
  padding: 10px;
}

.sun .navigation__list {
  color: #000;
}

.navigation__list-header {
  width: 100%;
  display: flex;
  gap: 20px;
  color: currentColor;
  text-decoration: none;
  border-bottom: 0.5px solid #fff;
  padding: 0 10px 10px 10px;
}

.navigation__list-logo {
  max-width: 40px;
  width: 100%;
  background: url(@/assets/pg-logo.svg);
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  border-radius: 50%;
  padding: 20px;
}

.navigation__list-title {
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
}
</style>
