import Keycloak from 'keycloak-js';

const keycloakSettings = {
  url: process.env.VUE_APP_BASE_KEYCLOAK_URL,
  realm: 'microservices-realm',
  clientId: 'admin-react-app',
};

const _kc = new Keycloak(keycloakSettings);

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const updateToken = (successCallback) => _kc.updateToken().then(successCallback).catch(doLogin);

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const ownerName = () => {
  return _kc.tokenParsed.preferred_username;
};

function initKeycloak(authCallback) {
  let roles = ['ROLE_ADMIN', 'ROLE_ACCOUNTANT'];

  _kc
    .init({
      onLoad: 'login-required',
    })
    .then((auth) => {
      if (auth) {
        if (hasRole(roles)) {
          authCallback();
        } else {
          doLogout();
        }
      } else {
        console.log('Not Authenticated');
        doLogin();
      }
    });
}

const KeycloakService = () => {
  return { initKeycloak, doLogin, doLogout, getToken, updateToken, hasRole, ownerName };
};

export default KeycloakService;
