<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  name: 'TableFiltersVue',

  props: {
    filtersList: Object,
    filterMethod: Function,
    selectRows: Function,
  },

  components: { VueDatePicker },

  data: () => {
    return {
      filters: {},
      selectInputFilters: {
        filterKey: '',
        filterValue: '',
        lastFilter: '',
      },
      date: '',
    };
  },

  methods: {
    filterHandler(evt) {
      if (evt.target.value) {
        this.filters[evt.target.name] = evt.target.value;
      } else {
        delete this.filters[evt.target.name];
      }
    },

    selectInputFilterHandler() {
      if (this.selectInputFilters.filterKey && this.selectInputFilters.filterValue) {
        this.filters[this.selectInputFilters.filterKey] = this.selectInputFilters.filterValue;
        this.selectInputFilters.lastFilter = this.selectInputFilters.filterKey;
      } else {
        delete this.filters[this.selectInputFilters.lastFilter];
      }
    },

    handleDate(date) {
      if (date) {
        this.filters.startDate = this.date[0];
        this.filters.endDate = this.date[1];
      } else {
        delete this.filters.createdAt;
      }
    },

    searchToFilters() {
      this.filterMethod(this.filters);
    },
  },
};
</script>

<template>
  <div class="table__filters">
    <select v-if="this.selectRows" @change="selectRows" class="table__filters-select">
      <option value="11">Кол-во строк</option>
      <option value="100">100</option>
      <option value="200">200</option>
      <option value="500">500</option>
      <option value="1000">1000</option>
    </select>
    <select
      class="table__filters-select"
      v-for="select in filtersList.selectFilters"
      :key="select.filterName"
      :name="select.filterName"
      @change="filterHandler">
      <option v-for="option in select.options" :key="option.value" :value="option.value">
        {{ option.name }}
      </option>
    </select>
    <!-- <div v-if="filtersList.selectFilters.length > 0" class="table__filters">
                                              <select @change="filterHandler" name="isActive" class="table__filters-select">
                                                <option value="">Статус</option>
                                                <option value="true">Активный</option>
                                                <option value="false">Не активный</option>
                                              </select>
                                              <select @change="filterHandler" name="type" class="table__filters-select">
                                                <option value="">Тип кошелька</option>
                                                <option value="OPERATOR">Оператор</option>
                                                <option value="MERCHANT">Мерчант</option>
                                              </select>
                                            </div> -->

    <select
      v-if="filtersList.selectInputFilters.length > 0"
      v-model="selectInputFilters.filterKey"
      name="select"
      @change="selectInputFilterHandler"
      class="table__filters-select">
      <option value="">Поле поиска</option>
      <option
        :value="filter.filterKey"
        v-for="(filter, index) in filtersList.selectInputFilters"
        :key="index">
        {{ filter.filterName }}
      </option>
    </select>
    <input
      v-if="filtersList.selectInputFilters.length > 0"
      @input="selectInputFilterHandler"
      name="input"
      class="table__filters-input"
      placeholder="Строка поиска"
      v-model="selectInputFilters.filterValue" />

    <VueDatePicker
      v-if="filtersList.selectInputFilters.length > 0"
      class="table__filters-date"
      name="datePicker"
      placeholder="Введите дату"
      v-model="date"
      model-type="yyyy-MM-dd"
      @update:model-value="handleDate"
      range
      :autoApply="true"
      :enable-time-picker="false"
      :dark="true"
      format="dd.MM.yyyy" />
    <button @click="searchToFilters" id="tile" class="table__-button">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        class="w-6 h-6">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
      </svg>
    </button>
  </div>
</template>

<style scoped>
.table__filters {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 0;
}

.table__filters-select-input {
  display: flex;
  gap: 20px;
}

.table__filters-select {
  max-width: fit-content;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: #e9e7e7;
  background: #242630;
  outline: none;
  border: 1px solid #e9e7e7;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
}

.table__filters-date {
  max-width: 250px;
}

.sun .table__filters-date input::placeholder {
  color: #060b27;
}

.table__filters-date input::placeholder {
  color: #2196f3;
  font-weight: 700;
}

.sun .table__filters-select {
  background: transparent;
  color: #060b27;
  border: 1px solid #e9e7e7;
}

.table__filters-select option {
  color: #fff;
  font-size: inherit;
  font-weight: inherit;
  padding: 10px 0;
}

.sun .table__filters-select option {
  color: #060b27;
}

.table__filters-select:focus {
  border: 1px solid #ff8630;
}

.table__filters-input {
  max-width: 200px;
  width: 100%;
  line-height: 19px;
  font-size: 14px;
  font-weight: 600;
  color: #e9e7e7;
  outline: none;
  border: 1px solid #e9e7e7;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
}

.sun .table__filters-input {
  color: #060b27;
  border: 1px solid #060b27;
}

.table__filters-input::placeholder {
  color: currentColor;
}

.table__filters-input:focus {
  border: 1px solid #ff8630;
  color: currentColor;
}

.sun .table__-button {
  color: #060b27;
  border: 1px solid #060b27;
}

.table__-button {
  max-width: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e9e7e7;
  border-radius: 5px;
  border: 1px solid #e9e7e7;
  transition: 0.3s;
  padding: 10px;
}

.table__-button svg {
  width: 20px;
  height: 20px;
}

.table__-button:hover {
  background-color: #2196f3;
  color: #fff;
}

.sun .table__-button:hover {
  background-color: #060b27;
  color: #fff;
}
</style>
