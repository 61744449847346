<script>
export default {
  name: 'NavigationItemVue',

  props: { title: String, path: String, access: Boolean },

  data: () => {
    return {};
  },
};
</script>
<template>
  <RouterLink v-if="access" :to="path" class="navigation__item">
    <slot></slot>
    <p class="navigation__item-title">{{ title }}</p>
  </RouterLink>
</template>

<style>
.navigation__item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  color: currentColor;
  text-decoration: none;
  border-radius: 10px;
  padding: 10px;
  transition: 0.3s ease-in-out;
}

.navigation__item:hover {
  color: #060b27;
  background-color: #fff;
}

.navigation__item-icon {
  max-width: 30px;
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation__item-icon svg {
  width: 25px;
  height: 25px;
}

.navigation__item-title {
  font-size: 12px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.1px;
}
</style>