<script>
import Pagination from './Pagination.vue';
import WalletsItem from './WalletsItem.vue';

export default {
  name: 'WalletsListVue',

  props: { listInfo: Object, paginationMethod: Function },

  components: { WalletsItem, Pagination },

  mounted() {
    this.listData = this.listInfo.walletsData;
  },

  beforeUpdate() {
    this.listData = this.listInfo.walletsData;
  },

  data: () => {
    return {
      listData: [],
    };
  },

  methods: {
    nextPage() {
      this.paginationMethod(this.listInfo.currentPage + 1, 6);
    },
    prevPage() {
      this.paginationMethod(this.listInfo.currentPage - 1, 6);
    },
  },
};
</script>

<template>
  <ul class="wallets__list">
    <WalletsItem
      v-for="(wallet, index) in listData"
      :wallet="wallet"
      :key="index"
      :class="{ wallets__item_disable: !wallet.isActive }" />
  </ul>

  <Pagination
    :paginationOptions="{
      pageNumber: listInfo.currentPage,
      pageCount: listInfo.totalPages,
      pageDisabled: listInfo.pageDisabled,
      nextPage,
      prevPage,
    }" />
</template>

<style scoped>
.wallets__list {
  width: 100%;
  min-height: 580px;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-wrap: wrap;
  gap: 20px;
  list-style-type: none;
  color: currentColor;
  margin: 0;
  padding: 0;
}
</style>
