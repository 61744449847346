const BASE_URL = process.env.VUE_APP_BASE_API_URL;

const checkResponse = (response) =>
  response.ok ? response.json() : Promise.reject(response.status);

function getAllWallets(page, size, filters, token) {
  return fetch(`${BASE_URL}/wallets/get-all/by-filters`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ page, size, filters }),
  }).then(checkResponse);
}

function getAllTransaction(page, size, filters, token) {
  return fetch(`${BASE_URL}/report/transactions/get-all/by-filters/between`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ page, size, filters }),
  }).then(checkResponse);
}

function getAllMerchants(page, size, filters, token) {
  return fetch(`${BASE_URL}/organization/merchants/get-all/by-filters`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ page, size, filters }),
  }).then(checkResponse);
}

function getAllServices(page, size, filters, token) {
  return fetch(`${BASE_URL}/organization/services/get-all/by-filters`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ page, size, filters }),
  }).then(checkResponse);
}

function getAllSubscriber(page, size, filters, token) {
  return fetch(`${BASE_URL}/subscribers/get-all/by-filters`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ page, size, filters }),
  }).then(checkResponse);
}

function getSubscriberProduct(page, size, filters, token) {
  return fetch(`${BASE_URL}/subscriptions/get-all/by-filters`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ page, size, filters }),
  }).then(checkResponse);
}

function innactiveSubscription(subscriptionIds, token) {
  return fetch(`${BASE_URL}/operation/unsubscribe-all`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ subscriptionIds }),
  }).then(checkResponse);
}

function getServiceFee(serviceId, token) {
  return fetch(`${BASE_URL}/organization/accounting/get/${serviceId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(checkResponse);
}

function createMerchant({ name, address, bin, owner, contractNumber }, token) {
  return fetch(`${BASE_URL}/organization/merchants/create`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ name, address, bin, owner, contractNumber }),
  }).then(checkResponse);
}

function createService(
  { merchantId, name, systemName, publicServiceId, pid, siteName, url },
  token,
) {
  return fetch(`${BASE_URL}/organization/services/create/without-fee`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ merchantId, name, systemName, publicServiceId, pid, siteName, url }),
  }).then(checkResponse);
}

function createFee(data, token) {
  return fetch(`${BASE_URL}/organization/accounting/create`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then(checkResponse);
}

function addBlackListUser(data, token) {
  return fetch(`${BASE_URL}/subscribers/block`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then(checkResponse);
}

function exportAllTransaction(filters, token) {
  return fetch(`${BASE_URL}/report/transactions/get-all/by-filters/between/download`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ filters }),
  })
    .then((res) => {
      return res.text();
    })
    .then((res) => {
      const blob = new Blob([res], { type: 'text/plain' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'Transaction.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    });
}

export {
  getAllWallets,
  getAllTransaction,
  exportAllTransaction,
  getAllMerchants,
  getAllServices,
  createMerchant,
  createService,
  getServiceFee,
  getAllSubscriber,
  createFee,
  getSubscriberProduct,
  innactiveSubscription,
  addBlackListUser,
};
