<script>
import ThemeButton from '@/components/ThemeButton.vue';

export default {
  name: 'HeaderVue',

  props: {
    pageTheme: Object,
    switchTheme: Function,
  },

  components: { ThemeButton },

  data: () => {
    return {};
  },
};
</script>

<template>
  <header class="header">
    <div class="header__profile">
      <!--      <img src="@/assets/profile-image.png" alt="" class="header__profile-image" />-->
    </div>
    <ThemeButton :theme="pageTheme" :switchTheme="switchTheme" />
    <button @click="$keycloak.doLogout" type="button" class="header__logout-button">
      <svg
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"></path>
      </svg>
    </button>
  </header>
</template>

<style scoped>
.header {
  width: 100%;
  min-height: 70px;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 15px;

  padding: 0 15px;
}

.header__profile {
  display: flex;
}

.header__profile-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  cursor: pointer;
}

.header__logout-button {
  width: 25px;
  height: 25px;
  cursor: pointer;
  color: currentColor;
  transition: 0.3s;
}

.header__logout-button:hover {
  opacity: 0.6;
}
</style>