import { createStore } from 'vuex';
import iconsObject from '@/vendor/icons';

export default createStore({
  state: {
    appName: 'ADMIN_PANEL',
    navigationIcons: iconsObject,
    language: 'ru',
    pageTexts: {},
    appErrors: {
      active: false,
      statusCode: '',
      text: '',
    },
    merchantData: {},
    serviceData: {},
    subscriberData: {},
  },
  getters: {},
  mutations: {
    setPageTexts(state, object) {
      state.pageTexts = object;
    },

    setAppErrors(state, object) {
      state.appErrors = object;
    },

    setMerchantData(state, object) {
      state.merchantData = object;
    },

    setServiceData(state, object) {
      state.serviceData = object;
    },

    setSubscriberData(state, object) {
      state.subscriberData = object;
    },
  },
  actions: {},
  modules: {},
});
