<script>
import { exportAllTransaction, getAllTransaction } from '@/utills/api';
import DataTable from './DataTable.vue';

import Preloader from './Preloader.vue';
import TableFilters from './TableFilters.vue';

export default {
  name: 'TransactionVue',

  props: { approvedRoles: Array },

  components: { Preloader, DataTable, TableFilters },

  mounted() {
    this.$keycloak.hasRole(this.approvedRoles)
      ? this.getTransactionData(0, 12)
      : this.$router.push('/');
  },

  data: () => {
    return {
      transactionInfo: {
        tableData: [],
        tableKey: 'transactions',
        currentPage: '',
        totalPages: '',
        totalRows: '',
        visibleRows: 11,
        pageDisabled: { first: true, last: true },
        exportActive: true,
        filters: {},
      },
      filtersList: {
        selectFilters: [],
        selectInputFilters: [
          {
            filterName: 'Номер транзакции',
            filterKey: 'transactionNumber',
          },
          {
            filterName: 'Мерчант',
            filterKey: 'merchantName',
          },
          {
            filterName: 'Сервис',
            filterKey: 'serviceName',
          },
          {
            filterName: 'Телефон',
            filterKey: 'phone',
          },
        ],
      },
      preloaderActive: false,
    };
  },

  methods: {
    getTransactionData(page, size) {
      this.preloaderActive = true;
      getAllTransaction(page, size, this.transactionInfo.filters, this.$keycloak.getToken())
        .then((res) => {
          this.transactionInfo.tableData = res.message.content;
          this.transactionInfo.totalRows = res.message.content.length;
          this.transactionInfo.currentPage = res.message.pageable.pageNumber;
          this.transactionInfo.totalPages = res.message.totalPages;
          this.transactionInfo.pageDisabled.first = res.message.first;
          this.transactionInfo.pageDisabled.last = res.message.last;
          this.preloaderActive = false;
        })
        .catch((err) => {
          this.$store.commit('setAppErrors', {
            active: true,
            statusCode: err,
            text: 'Произошла ошибка сервера',
          });
          this.$router.push('errors');
        });
    },

    selectRows(evt) {
      this.visibleRows = +evt.target.value;
      this.getTransactionData(0, this.visibleRows);
    },

    tableDataFilter(filters) {
      this.transactionInfo.filters = filters;
      this.getTransactionData(0, this.visibleRows ? this.visibleRows : 12);
    },

    exportAllPages() {
      exportAllTransaction(this.transactionInfo.filters, this.$keycloak.getToken());
    },
  },
};
</script>

<template>
  <section class="transaction">
    <TableFilters
      :selectRows="selectRows"
      :filtersList="filtersList"
      :filterMethod="tableDataFilter" />
    <Preloader v-if="preloaderActive" />
    <DataTable
      v-else
      :tableInfo="transactionInfo"
      :tableSize="{ height: '670px', width: '100%', flex: 0 }"
      :paginationMethod="getTransactionData"
      :exportAllPages="exportAllPages"></DataTable>
  </section>
</template>

<style>
.transaction {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
</style>
