<script>
import { getAllMerchants } from '@/utills/api';
import Preloader from '@/components/Preloader.vue';
import DataTable from '@/components/DataTable.vue';
export default {
  name: 'MerchantsVue',

  components: { DataTable, Preloader },

  props: { approvedRoles: Array },

  mounted() {
    this.$keycloak.hasRole(this.approvedRoles)
      ? this.getMerchantsList(0, 12)
      : this.$router.push('/');
  },

  data: () => {
    return {
      merchantsInfo: {
        tableData: [],
        tableKey: 'merchants',
        currentPage: '',
        totalPages: '',
        totalRows: '',
        visibleRows: 11,
        pageDisabled: { first: true, last: true },
        exportActive: false,
        filters: {},
      },
      preloaderActive: false,
    };
  },

  methods: {
    getMerchantsList(page, size) {
      this.preloaderActive = true;
      getAllMerchants(page, size, this.merchantsInfo.filters, this.$keycloak.getToken()).then(
        (res) => {
          this.merchantsInfo.tableData = res.message.content;
          this.merchantsInfo.totalRows = res.message.content.length;
          this.merchantsInfo.currentPage = res.message.pageable.pageNumber;
          this.merchantsInfo.totalPages = res.message.totalPages;
          this.merchantsInfo.pageDisabled.first = res.message.first;
          this.merchantsInfo.pageDisabled.last = res.message.last;
          this.preloaderActive = false;
        },
      );
    },
  },
};
</script>

<template>
  <section class="merchants">
    <div class="merchants__create">
      <router-link to="merchant-create" class="merchants__create-button">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
        </svg>
        Создать мерчанта
      </router-link>
    </div>
    <Preloader v-if="preloaderActive" />
    <DataTable
      v-else
      :tableInfo="merchantsInfo"
      :tableSize="{ height: '670px', width: '100%', flex: 1 }" />
  </section>
</template>

<style>
.merchants {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.merchants__create {
  width: 100%;
}

.merchants__create-button {
  width: 200px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
  color: #e9e7e7;
  outline: none;
  border: 1px solid #e9e7e7;
  text-decoration: none;
  transition: 0.5s ease-in-out;
  padding: 10px;
  margin-left: auto;
}

.merchants__create-button svg {
  width: 20px;
  height: 20px;
}

.merchants__create-button:hover {
  background-color: #2196f3;
  color: #fff;
}

.sun .merchants__create-button {
  background-color: #f5f6fa;
  color: #060b27;
}

.sun .merchants__create-button:hover {
  background-color: #060b27;
  color: #fff;
}
</style>
