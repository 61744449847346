<script>
import NavigationHeader from './NavigationHeader.vue';
import NavigationList from './NavigationList.vue';

export default {
  name: 'NavigationVue',

  components: { NavigationHeader, NavigationList },

  data: () => {
    return {};
  },

  methods: {},
};
</script>

<template>
  <div class="navigation">
    <NavigationHeader />
    <NavigationList />
  </div>
</template>

<style scoped>
.navigation {
  max-width: 270px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  overflow: hidden;
  color: #fff;
}
</style>
