<script>
import Preloader from '@/components/Preloader.vue';
import BackpageButtonVue from '@/components/BackpageButton.vue';
import { createService } from '@/utills/api';
import CreateFormVue from '@/components/CreateForm.vue';

export default {
  name: 'CreateServiceVue',
  components: { CreateFormVue, BackpageButtonVue, Preloader },

  mounted() {
    this.$store.state.merchantData.id ? '' : this.$router.push('merchants');
  },

  data: () => {
    return {
      preloaderActive: false,
      preloaderComplete: false,
    };
  },

  computed: {
    formOptions() {
      return {
        title: 'Создание сервиса',
        submitText: 'Создать сервис',
        fields: [
          { name: 'Имя сервиса', type: 'text', model: 'name' },
          { name: 'Системное имя', type: 'text', model: 'systemName' },
          { name: 'PID', type: 'number', model: 'pid' },
          { name: 'Публичный сервис ID', type: 'number', model: 'publicServiceId' },
          { name: 'Имя сайта', type: 'text', model: 'siteName' },
          { name: 'Ссылка на сайт', type: 'text', model: 'url' },
        ],
      };
    },
  },

  methods: {
    serviceCreateHandler(data) {
      this.preloaderActive = true;
      data.merchantId = this.$store.state.merchantData.id;
      createService(data, this.$keycloak.getToken()).then((res) => {
        res.success ? (this.preloaderComplete = true) : '';
        // setTimeout(() => {
        //   this.$router.push('create-merchant');
        // }, 4000);
      });
    },
  },
};
</script>

<template>
  <section class="create-merchant">
    <BackpageButtonVue />
    <Preloader v-if="preloaderActive" :preloaderComplete="preloaderComplete" />
    <CreateFormVue v-else :formOptions="formOptions" :createHandler="serviceCreateHandler" />
  </section>
</template>

<style scoped>
.create-merchant {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 20px;
}
</style>
