import { createRouter, createWebHistory } from 'vue-router';

import Wallets from '@/components/Wallets.vue';
import Transaction from '@/components/Transaction.vue';
import Home from '@/components/Home.vue';
import Error from '@/components/Error.vue';
import Merchants from '@/components/Merchants.vue';
import Subscribers from '@/components/Subscribers.vue';
import CreateMerchant from '@/components/CreateMerchant.vue';
import CreateService from '@/components/CreateService.vue';
import CreateFees from '@/components/CreateFees.vue';
import OrganizationProfile from '@/components/OrganizationProfile.vue';

const routes = [
  {
    path: '/',
    name: 'Главная',
    component: Home,
    meta: {
      title: 'Главная',
    },
  },
  {
    path: '/wallets',
    name: 'Кошельки',
    component: Wallets,
    meta: {
      title: 'Кошельки',
    },
    props: { approvedRoles: ['ROLE_ADMIN', 'ROLE_ACCOUNTANT'] },
  },
  {
    path: '/merchants',
    name: 'Список Мерчантов',
    component: Merchants,
    meta: {
      title: 'Список мерчантов',
    },
    props: { approvedRoles: ['ROLE_ADMIN', 'ROLE_ACCOUNTANT'] },
  },
  {
    path: '/subscribers',
    name: 'Подписки',
    component: Subscribers,
    meta: {
      title: 'Подписки',
    },
    props: { approvedRoles: ['ROLE_ADMIN', 'ROLE_ACCOUNTANT'] },
  },
  {
    path: '/transaction-history',
    name: 'История транзакций',
    component: Transaction,
    meta: {
      title: 'История транзакций',
    },
    props: { approvedRoles: ['ROLE_ADMIN', 'ROLE_ACCOUNTANT'] },
  },
  {
    path: '/merchant-create',
    name: 'Заведение мерчанта',
    component: CreateMerchant,
    meta: {
      title: 'Заведение мерчанта',
    },
    props: { approvedRoles: ['ROLE_ADMIN', 'ROLE_ACCOUNTANT'] },
  },
  {
    path: '/service-create',
    name: 'Заведение сервиса',
    component: CreateService,
    meta: {
      title: 'Заведение сервиса',
    },
    props: { approvedRoles: ['ROLE_ADMIN', 'ROLE_ACCOUNTANT'] },
  },
  {
    path: '/fee-create',
    name: 'Создание комиссий',
    component: CreateFees,
    meta: {
      title: 'Заведение сервиса',
    },
    props: { approvedRoles: ['ROLE_ADMIN', 'ROLE_ACCOUNTANT'] },
  },
  {
    path: '/merchant-page',
    name: 'Страница мерчанта',
    component: OrganizationProfile,
    meta: {
      title: 'Страница мерчанта',
    },

    props: {
      approvedRoles: ['ROLE_ADMIN', 'ROLE_ACCOUNTANT'],
      profileOption: {
        key: 'services',
        title: 'Информация о мерчанте',
        subtitle: 'Список Cервисов',
        fields: [
          { title: 'Имя', text: 'name' },
          { title: 'Номер контракта', text: 'contractNumber' },
          { title: 'Дата создания', text: 'createdAt' },
          { title: 'Адрес', text: 'publicAddress' },
          { title: 'BIN', text: 'bin' },
        ],
      },
    },
  },
  {
    path: '/service-page',
    name: 'Страница сервиса',
    component: OrganizationProfile,
    meta: {
      title: 'Страница сервиса',
    },

    props: {
      approvedRoles: ['ROLE_ADMIN', 'ROLE_ACCOUNTANT'],
      profileOption: {
        key: 'fee',
        title: 'Информация о сервисе',
        subtitle: 'Список комиссий',
        fields: [
          { title: 'Имя сервиса', text: 'name' },
          { title: 'PID', text: 'pid' },
          { title: 'Дата создания', text: 'createdAt' },
          { title: 'Cайт', text: 'siteName', url: true },
        ],
      },
    },
  },
  {
    path: '/subscriber-page',
    name: 'Страница подписчика',
    component: OrganizationProfile,
    meta: {
      title: 'Страница подписчика',
    },

    props: {
      approvedRoles: ['ROLE_ADMIN', 'ROLE_ACCOUNTANT'],
      profileOption: {
        key: 'subscribe',
        title: 'Информация о подписчике',
        subtitle: 'Подписки',
        fields: [
          { title: 'Номер телефона', text: 'entity' },
          { title: 'Статус', text: 'status' },
        ],
      },
    },
  },
  {
    path: '/errors',
    name: 'Произошла ошибка',
    component: Error,
    meta: {
      title: 'Произошла ошибка',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const title = `${to.meta.title}`;

  if (title) {
    document.title = title;
  }
  // Continue resolving the route
  next();
});

export default router;
