<script>
export default {
  name: 'NavigationHeaderVue',

  data: () => {
    return {};
  },

  methods: {},
};
</script>

<template>
  <div class="navigation__header">
    <h3 class="navigation__header-title">{{ this.$route.name }}</h3>
  </div>
</template>

<style>
.navigation__header {
  position: fixed;
  top: 0;
  left: 0;
  max-width: 270px;
  width: 100%;
  display: flex;
  padding: 20px 30px 20px 50px;
}

.sun .navigation__header {
  color: #060b27;
}

.navigation__header-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
}
</style>