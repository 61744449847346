export const gridLocaleRu = {
  //? Filters
  lessThan: 'Меньше',
  greaterThan: 'Больше',
  lessThanOrEqual: 'Меньше или равно',
  greaterThanOrEqual: 'Больше или равно',
  equals: 'Равняется',
  notEqual: 'Не равняется',
  inRange: 'Диапазон',
  inRangeStart: 'от',
  inRangeEnd: 'до',

  // Text Filter
  contains: 'Содержит',
  notContains: 'Не содержит',

  //? Button
  applyFilter: 'Применить',
  resetFilter: 'Сбросить',
};
