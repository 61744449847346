<script>
import { getAllSubscriber } from '@/utills/api';
import DataTable from './DataTable.vue';

import Preloader from './Preloader.vue';
import TableFilters from './TableFilters.vue';

export default {
  name: 'SubscribersVue',

  props: { approvedRoles: Array },

  components: { DataTable, Preloader, TableFilters },

  mounted() {
    this.$keycloak.hasRole(this.approvedRoles)
      ? this.getSubscribesData(0, 11)
      : this.$router.push('/');
  },

  data: () => {
    return {
      subscribeInfo: {
        tableData: [],
        tableKey: 'subscribers',
        currentPage: '',
        totalPages: '',
        totalRows: '',
        visibleRows: 12,
        pageDisabled: { first: true, last: true },
        exportActive: true,
        filters: {},
      },

      filtersList: {
        selectFilters: [],
        selectInputFilters: [
          {
            filterName: 'Номер телефона',
            filterKey: 'phone',
          },
        ],
      },

      preloaderActive: false,
      componentAccess: null,
    };
  },

  methods: {
    getSubscribesData(page, size) {
      this.preloaderActive = true;
      getAllSubscriber(page, size, this.subscribeInfo.filters, this.$keycloak.getToken())
        .then((res) => {
          this.subscribeInfo.tableData = res.message.content;
          this.subscribeInfo.totalRows = res.message.content.length;
          this.subscribeInfo.currentPage = res.message.pageable.pageNumber;
          this.subscribeInfo.totalPages = res.message.totalPages;
          this.subscribeInfo.pageDisabled.first = res.message.first;
          this.subscribeInfo.pageDisabled.last = res.message.last;
          this.preloaderActive = false;
        })
        .catch((err) => {
          this.$store.commit('setAppErrors', {
            active: true,
            statusCode: err,
            text: 'Произошла ошибка сервера',
          });
          this.$router.push('errors');
        });
    },

    subscribeDataFilter(filters) {
      this.subscribeInfo.filters = filters;
      this.getSubscribesData(0, this.visibleRows);
    },

    selectRows(evt) {
      this.visibleRows = evt.target.value;
      this.getSubscribesData(0, this.visibleRows);
    },
  },
};
</script>

<template>
  <section class="subscribes">
    <TableFilters
      :selectRows="selectRows"
      :filtersList="filtersList"
      :filterMethod="subscribeDataFilter" />

    <Preloader v-if="preloaderActive" />
    <DataTable
      v-if="!preloaderActive"
      :tableInfo="subscribeInfo"
      :paginationMethod="getSubscribesData"
      :tableSize="{ height: '600px', width: '100%', flex: 1 }"></DataTable>
  </section>
</template>

<style scoped>
.subscribes {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
</style>
