import store from '@/store';
import router from '@/router';

export function fieldConfig(key) {
  let walletsFields = [
    {
      field: 'walletName',
      headerName: 'Имя кошелька',
    },
    { field: 'type', headerName: 'Тип кошелька' },
    {
      field: 'moneyAmount',
      headerName: 'Баланс',
      cellRenderer: (value) => {
        return value.data.moneyAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      },
    },
    {
      field: 'info',
      headerName: 'Подробная информация',

      cellRenderer: () => {
        return ` <button type='button' class='table-info'>
              <svg
        fill='none'
        stroke='currentColor'
        stroke-width='1.8'
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
        aria-hidden='true'>
        <path
          stroke-linecap='round'
          stroke-linejoin='round'
          d='M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z'></path>
      </svg>
    </button>`;
      },
      hide: true,
    },
    {
      field: 'addBalance',
      headerName: 'Пополнить',
      hide: true,

      cellRenderer: () => {
        return `<button class='table-transaction-button' type='button'></button>`;
      },
    },
    {
      field: 'withdrawBalance',
      headerName: 'Снять',

      cellRenderer: () => {
        return `<button class='table-transaction-button' type='button' style='background-color: #FA8072;'>
              </button>`;
      },
      hide: true,
    },
    {
      field: 'isActive',
      headerName: 'Статус',
      valueGetter: (value) => {
        return value.data.isActive ? 'Активный' : 'Не активный';
      },
      cellRenderer: (value) => {
        if (value.data.isActive) {
          return `<p class='status-wallet'>Активный</p>`;
        } else {
          return `<p class='status-wallet status-wallet_color_red'>Не активный</p>`;
        }
      },
    },
  ];

  let transactionFields = [
    {
      field: 'createdAt',
      headerName: 'Дата создания',
      cellRenderer: (value) => {
        return new Date(value.data.createdAt).toLocaleString();
      },
    },
    {
      field: 'globalTagId',
      headerName: 'ID транзакции',
    },
    // {
    //   field: 'transactionNumber',
    //   headerName: 'Номер транзакции',
    // },
    {
      field: 'serviceId',
      headerName: 'ID сервиса',
    },
    {
      field: 'serviceName',
      headerName: 'Сервис',
    },
    {
      field: 'phone',
      headerName: 'Телефон',
    },
    {
      field: 'merchantId',
      headerName: 'ID мерчанта',
    },
    {
      field: 'merchantName',
      headerName: 'Мерчант',
    },
    {
      field: 'agentName',
      headerName: 'Агент (ОСС)',
      maxWidth: 170,
    },
    {
      field: 'totalValue',
      headerName: 'Сумма оплаты',
      maxWidth: 110,
    },
    {
      field: 'lowValue',
      headerName: 'Нижняя комиссия',
      maxWidth: 110,
    },
    {
      field: 'highValue',
      headerName: 'Верхняя комиссия ',
      maxWidth: 110,
    },

    {
      field: 'serviceValue',
      headerName: 'Комиссия сервиса',
      maxWidth: 110,
    },
    {
      field: 'extraValue',
      headerName: 'Экстра комиссия',
      maxWidth: 110,
    },
    {
      field: 'operationType',
      headerName: 'Тип транзакции',
    },
    // {
    //   field: 'operatorName',
    //   headerName: 'Оператор ЭД',
    // },
  ];

  let merchantsFields = [
    {
      field: 'name',
      headerName: 'Имя мерчанта',
    },
    { field: 'bin', headerName: 'BIN' },
    {
      field: 'contractNumber',
      headerName: 'Номер контракта',
    },
    {
      field: '',
      headerName: 'Профиль',
      onCellClicked: (value) => {
        router.push('/merchant-page');
        store.commit('setMerchantData', value.data);
      },
      cellRenderer: () => {
        return `<router-link
         class='more-table__button'
       
          >
          Подробнее
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            stroke-width='2'
            stroke='currentColor'>
            <path
              stroke-linecap='round'
              stroke-linejoin='round'
              d='M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3' />
          </svg>
        </router-link>`;
      },
    },
  ];

  let servicesFields = [
    { field: 'name', headerName: 'Имя сервиса' },
    { field: 'pid', headerName: 'PID' },
    {
      field: 'createdAt',
      headerName: 'Дата создания',
      cellRenderer: (value) => {
        return new Date(value.data.createdAt).toLocaleString();
      },
    },
    {
      field: 'siteName',
      headerName: 'Сайт',
      cellRenderer: (value) => {
        return `<a class='table-link' target="_blank" href="${value.data.url}">${value.data.siteName}</a>`;
      },
    },
    {
      field: '',
      headerName: 'Профиль',
      onCellClicked: (value) => {
        router.push('/service-page');
        store.commit('setServiceData', value.data);
      },
      cellRenderer: () => {
        return `<router-link
         class='more-table__button'>
          Подробнее
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            stroke-width='2'
            stroke='currentColor'>
            <path
              stroke-linecap='round'
              stroke-linejoin='round'
              d='M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3' />
          </svg>
        </router-link>`;
      },
    },
  ];

  let feeFields = [
    { headerName: 'Агент', field: 'agentName' },
    { headerName: 'Сумма комиссии', field: 'feeValue' },
    { headerName: 'Тип комиссии', field: 'feeMethod' },
    {
      headerName: 'Комиссия оператора',
      field: 'parts',
      cellRenderer: (data) => {
        let container;
        data.value.forEach((item) => {
          if (item.partType === 'OPERATOR') {
            container = item.partValue;
          }
        });
        return container ? container : 0;
      },
    },
    {
      headerName: 'Комиссия агента',
      field: 'parts',
      cellRenderer: (data) => {
        let container;
        data.value.forEach((item) => {
          if (item.partType === 'AGENT') {
            container = item.partValue;
          }
        });
        return container ? container : 0;
      },
    },
    {
      headerName: 'Комиссия сервиса',
      field: 'parts',
      cellRenderer: (data) => {
        let container;
        data.value.forEach((item) => {
          if (item.partType === 'SERVICE') {
            container = item.partValue;
          }
        });
        return container ? container : 0;
      },
    },
  ];

  let subscribersField = [
    {
      field: 'entity',
      headerName: 'Подписчик',
    },
    {
      field: 'status',
      headerName: 'Статус',
    },
    {
      field: '',
      headerName: 'Подписки',
      onCellClicked: (value) => {
        router.push('/subscriber-page');
        store.commit('setSubscriberData', value.data);
      },
      cellRenderer: () => {
        return `<router-link
         class='more-table__button'
       
          >
          Подробнее
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            stroke-width='2'
            stroke='currentColor'>
            <path
              stroke-linecap='round'
              stroke-linejoin='round'
              d='M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3' />
          </svg>
        </router-link>`;
      },
    },
  ];

  let subscribeFields = [
    {
      field: 'product.name',
      headerName: 'Продукт',
      maxWidth: 300,
      checkboxSelection: true,
      headerCheckboxSelection: true,

      showDisabledCheckboxes: true,
    },
    {
      field: 'product.amount',
      headerName: 'Цена продукта',
      maxWidth: 120,
    },
    {
      field: 'product.period',
      headerName: 'Период',
      maxWidth: 120,
    },
    {
      field: 'product.rebillingType',
      headerName: 'Тип ребиллинга',
      maxWidth: 120,
    },
    {
      field: 'status',
      headerName: 'Статус подписки',
      maxWidth: 220,
      valueGetter: (value) => {
        return value.data.status === 'ACTIVE' ? 'Активная' : 'Не активная';
      },
      cellRenderer: (value) => {
        switch (value.data.status) {
          case 'ACTIVE':
            return `<p class='status-wallet'>Активная</p>`;
          case 'INACTIVE':
            return `<p class='status-wallet status-wallet_color_red'>Не активная</p>`;
          case 'NOT_PAID':
            return `<p class='status-wallet status-wallet_color_red'>Ожидает оплаты</p>`;
          case 'REPAYMENT_EXPECTED':
            return `<p class='status-wallet status-wallet_color_red'>Ожидание продления</p>`;
          case 'PAID':
            return `<p class='status-wallet'>Активная</p>`;
          default:
            return `<p class='status-wallet'>НЕИЗВЕСТНО</p>`;
        }
      },
    },

    {
      field: 'subscribedAt',
      headerName: 'Дата подписки',
      cellRenderer: (value) => {
        return new Date(value.data.subscribedAt).toLocaleString();
      },
    },
  ];

  const fields = {
    wallets: walletsFields,
    transactions: transactionFields,
    merchants: merchantsFields,
    services: servicesFields,
    fee: feeFields,
    subscribers: subscribersField,
    subscribe: subscribeFields,
  };

  return fields[key];
}
