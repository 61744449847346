import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import KeycloakService from './services/KeycloakService';
import '@/main.css';

const renderApp = () => {
  createApp(App).use(store).use(router).use(KeycloakPlugin).mount('#app');
};

let keycloak = KeycloakService();
keycloak.initKeycloak(renderApp);
const KeycloakPlugin = {
  install: (app) => {
    app.config.globalProperties.$keycloak = keycloak;
  },
};
