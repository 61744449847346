<script>
export default {
  name: 'PaginationVue',

  props: { paginationOptions: Object },

  data: () => {
    return {};
  },
};
</script>

<template>
  <div class="pagination">
    <button
      class="pagination__button prev-button"
      :disabled="paginationOptions.pageDisabled.first"
      @click="paginationOptions.prevPage"
      type="button">
      <svg
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true">
        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5"></path>
      </svg>
    </button>
    <div class="pagination__links">
      <p class="pagination__link">{{ paginationOptions.pageNumber + 1 }}</p>
      <p class="pagination__text">ИЗ</p>
      <button class="pagination__link">{{ paginationOptions.pageCount }}</button>
    </div>

    <button
      class="pagination__button next-button"
      :disabled="paginationOptions.pageDisabled.last"
      @click="paginationOptions.nextPage"
      type="button">
      <svg
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true">
        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5"></path>
      </svg>
    </button>
  </div>
</template>

<style>
.pagination {
  max-width: 600px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 15px;

  margin-top: auto;
}
.pagination__button {
  max-width: 20px;
  width: 100%;
  height: 20px;
  border-radius: 50%;
  color: currentColor;
  transition: 0.3s;
}

.pagination__button svg {
  stroke-width: 4px;

  margin: auto;
}
.prev-button svg {
  rotate: 180deg;
  display: block;
}

.pagination__links {
  display: flex;
  align-items: center;
  box-sizing: content-box;
  gap: 20px;
}

.pagination__links button {
  cursor: auto;
}

.pagination__link {
  width: 20px;
  height: 20px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  color: currentColor;
  cursor: pointer;
  border-radius: 50%;

  transition: 0.3s;
}

.pagination__text {
  font-size: 16px;
  font-weight: 600;
}
</style>
