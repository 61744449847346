<script>
import DataTable from '@/components/DataTable.vue';
import BackpageButton from '@/components/BackpageButton.vue';
import {
  addBlackListUser,
  getAllServices,
  getServiceFee,
  getSubscriberProduct,
  innactiveSubscription,
} from '@/utills/api';
import Preloader from './Preloader.vue';
import TableFilters from './TableFilters.vue';
import Popup from './Popup.vue';
export default {
  name: 'OrganizationProfile',

  props: { profileOption: Object },

  components: { BackpageButton, DataTable, Preloader, TableFilters, Popup },

  created() {
    this.dataInfo.tableKey = this.profileOption.key;

    if (this.profileOption.key === 'subscribe') {
      this.filtersList.subscribe.filterMethod = (filters) => {
        this.dataInfo.filters = filters;
        this.getSubscribeList(0, this.dataInfo.visibleRows);
      };
    }
  },

  mounted() {
    this.profileInit(this.profileOption.key);
  },

  computed: {},

  data: () => {
    return {
      dataInfo: {
        tableData: [],
        tableKey: '',
        currentPage: '',
        totalPages: '',
        totalRows: '',
        visibleRows: 7,
        pageDisabled: { first: true, last: true },
        exportActive: false,
        filters: {},
      },
      preloaderActive: false,
      preloaderComplete: false,
      profileData: {},
      filtersList: {
        subscribe: {
          filterMethod: null,
          selectFilters: [],
          selectInputFilters: [
            {
              filterName: 'Продукт',
              filterKey: 'name',
            },
          ],
        },
      },
      blackListDescription: '',
      popupActive: false,
    };
  },

  methods: {
    profileInit(key) {
      const merchantPageHandler = () => {
        this.$store.state.merchantData.id
          ? this.getServiceList(0, this.dataInfo.visibleRows)
          : this.$router.push('merchants');
        this.profileData = this.$store.state.merchantData;
      };

      const servicePageHandler = () => {
        this.$store.state.serviceData.id
          ? this.getFeeList()
          : this.$router.push('organization-page');
        this.profileData = this.$store.state.serviceData;
      };

      const subscribePageHandler = () => {
        this.$store.state.subscriberData.id
          ? this.getSubscribeList(0, this.dataInfo.visibleRows)
          : this.$router.push('subscribers');
        this.profileData = this.$store.state.subscriberData;
      };

      const initFunction = {
        services: merchantPageHandler,
        fee: servicePageHandler,
        subscribe: subscribePageHandler,
      };
      initFunction[key]();
    },

    getServiceList(page, size) {
      this.preloaderActive = true;
      this.dataInfo.filters.merchantId = this.$store.state.merchantData.id;
      getAllServices(page, size, this.dataInfo.filters, this.$keycloak.getToken()).then((res) => {
        this.dataInfo.tableData = res.message.content;
        this.dataInfo.totalRows = res.message.content.length;
        this.dataInfo.currentPage = res.message.pageable.pageNumber;
        this.dataInfo.totalPages = res.message.totalPages;
        this.dataInfo.pageDisabled.first = res.message.first;
        this.dataInfo.pageDisabled.last = res.message.last;
        this.preloaderActive = false;
      });
    },

    getFeeList() {
      getServiceFee(this.$store.state.serviceData.id, this.$keycloak.getToken()).then((res) => {
        res.message.map((item) => {
          item?.fees.map((fee) => {
            fee.agentName = item.agentName;

            this.dataInfo.tableData.push(fee);
          });
        });
      });
    },

    getSubscribeList(page, size) {
      this.preloaderActive = true;
      this.dataInfo.filters.subscriberId = this.$store.state.subscriberData.id;
      // this.dataInfo.filters.subscriberId = '88f80479-eaab-40eb-85c0-ffee918fbd0e';
      getSubscriberProduct(page, size, this.dataInfo.filters, this.$keycloak.getToken()).then(
        (res) => {
          this.dataInfo.tableData = res.message.content;
          this.dataInfo.totalRows = res.message.content.length;
          this.dataInfo.currentPage = res.message.pageable.pageNumber;
          this.dataInfo.totalPages = res.message.totalPages;
          this.dataInfo.pageDisabled.first = res.message.first;
          this.dataInfo.pageDisabled.last = res.message.last;
          this.preloaderActive = false;
        },
      );
    },

    paginationHandler(page, size) {
      this.getSubscribeList(page, size);
      let getDataFunction = {
        subscribe: this.getSubscribeList,
        services: this.getServiceList,
      };

      getDataFunction[this.dataInfo.tableKey](page, size);
    },

    disabledSubscription(data) {
      if (data.length <= 0) {
        return;
      } else {
        this.preloaderActive = true;
        let subscriptionIds = [];
        data.forEach((item) => {
          subscriptionIds.push(item.id);
        });
        innactiveSubscription(subscriptionIds, this.$keycloak.getToken()).then((res) => {
          res.success ? (this.preloaderComplete = true) : '';
          setTimeout(() => {
            (this.preloaderActive = false), (this.preloaderComplete = false);
            this.getSubscribeList(0, this.dataInfo.visibleRows);
          }, 2000);
        });
      }
    },

    userBlackListHandler() {
      let data = {
        appName: this.$store.state.appName,
        description: this.blackListDescription,
        owner: this.$keycloak.ownerName(),
        phone: this.$store.state.subscriberData.entity,
      };
      this.preloaderActive = true;
      this.popupActive = false;
      addBlackListUser(data, this.$keycloak.getToken()).then((res) => {
        res.success ? (this.preloaderComplete = true) : '';
        setTimeout(() => {
          (this.preloaderActive = false), (this.preloaderComplete = false);
          this.getSubscribeList(0, this.dataInfo.visibleRows);
        }, 2000);
      });
    },

    closePop() {
      this.popupActive = false;
    },
  },
};
</script>

<template>
  <section class="organization-page">
    <div class="organization-page__header-options">
      <BackpageButton />
      <router-link
        v-if="this.profileOption.key === 'services'"
        to="service-create"
        class="organization__create-button">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
        </svg>
        Создать новый сервис
      </router-link>
      <button
        v-if="this.profileOption.key === 'subscribe'"
        class="organization__create-button"
        @click="popupActive = true">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 12.75c1.148 0 2.278.08 3.383.237 1.037.146 1.866.966 1.866 2.013 0 3.728-2.35 6.75-5.25 6.75S6.75 18.728 6.75 15c0-1.046.83-1.867 1.866-2.013A24.204 24.204 0 0112 12.75zm0 0c2.883 0 5.647.508 8.207 1.44a23.91 23.91 0 01-1.152 6.06M12 12.75c-2.883 0-5.647.508-8.208 1.44.125 2.104.52 4.136 1.153 6.06M12 12.75a2.25 2.25 0 002.248-2.354M12 12.75a2.25 2.25 0 01-2.248-2.354M12 8.25c.995 0 1.971-.08 2.922-.236.403-.066.74-.358.795-.762a3.778 3.778 0 00-.399-2.25M12 8.25c-.995 0-1.97-.08-2.922-.236-.402-.066-.74-.358-.795-.762a3.734 3.734 0 01.4-2.253M12 8.25a2.25 2.25 0 00-2.248 2.146M12 8.25a2.25 2.25 0 012.248 2.146M8.683 5a6.032 6.032 0 01-1.155-1.002c.07-.63.27-1.222.574-1.747m.581 2.749A3.75 3.75 0 0115.318 5m0 0c.427-.283.815-.62 1.155-.999a4.471 4.471 0 00-.575-1.752M4.921 6a24.048 24.048 0 00-.392 3.314c1.668.546 3.416.914 5.223 1.082M19.08 6c.205 1.08.337 2.187.392 3.314a23.882 23.882 0 01-5.223 1.082" />
        </svg>

        Отписать от всего и внести в чёрный список
      </button>
      <router-link
        v-if="this.profileOption.key === 'fee'"
        to="fee-create"
        class="organization__create-button">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          class="w-6 h-6">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
        </svg>

        Создать комиссии
      </router-link>
    </div>
    <div class="organization-page__info">
      <h3 class="page-info__title">{{ profileOption.title }}</h3>
      <ul class="page-info__list">
        <li v-for="(item, index) in profileOption.fields" class="info-list__item" :key="index">
          <p class="info-list__title">
            {{ item.title }}:
            <a v-if="item.url" :href="profileData.url" target="_blank" class="info-list__text">{{
              profileData[item.text]
            }}</a>
            <span v-else class="info-list__text">{{ profileData[item.text] }}</span>
          </p>
        </li>
      </ul>

      <h3 class="page-info__title">{{ profileOption.subtitle }}</h3>
      <TableFilters
        v-if="filtersList[dataInfo.tableKey]?.filterMethod"
        :filtersList="filtersList[dataInfo.tableKey]"
        :filterMethod="filtersList[dataInfo.tableKey]?.filterMethod" />

      <Preloader v-if="preloaderActive" :preloaderComplete="preloaderComplete" />
      <DataTable
        v-else
        :tableInfo="dataInfo"
        :tableSize="{ height: '400px', width: '100%', flex: 1 }"
        :paginationMethod="paginationHandler"
        :selectedFunc="{ func: disabledSubscription, text: 'Отключить подписки' }" />
    </div>
    <Transition>
      <Popup v-if="popupActive" :closePop="closePop" :popupHanlder="userBlackListHandler">
        <p class="popup__title">
          Вы хотите внести в чёрный список подписчика с номером
          {{ $store.state.subscriberData.entity }}
        </p>

        <select class="organization__select" v-model="blackListDescription">
          <option value="По просьбе клиента" selected>По просьбе клиента</option>
          <option value="По просьбе оператора">По просьбе оператора</option>
        </select>
        <p class="popup__subtitle popup__subtitle_red">Пожалуйста укажите причину</p>

        <button
          @click="userBlackListHandler"
          type="button"
          class="table-export-button"
          :disabled="!blackListDescription">
          Внести в чёрный список
        </button>
      </Popup>
    </Transition>
  </section>
</template>

<style scoped>
.organization-page {
  width: 100%;
}

.organization-page__header-options {
  display: flex;
}

.organization-page__info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.page-info__title {
  font-size: 24px;
  font-weight: 700;
}

.page-info__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  list-style-type: none;
  background: #45464f;
  border-radius: 5px;
  padding: 20px;
  margin: 0;
}

.sun .page-info__list {
  background: #f5f6fa;
}

.info-list__title {
  font-size: 14px;
  font-weight: 500;
  color: #9caabc;
}

.info-list__text {
  margin-left: 5px;
  color: #fff;
}
.sun .info-list__text {
  color: #060b27;
}

.organization-page__service {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.organization__create-button {
  width: 200px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
  color: #e9e7e7;
  outline: none;
  border: 1px solid #e9e7e7;
  text-decoration: none;
  transition: 0.5s ease-in-out;
  padding: 10px;
  margin-left: auto;
}

.organization__create-button svg {
  width: 20px;
  height: 20px;
}

.organization__create-button:hover {
  background-color: #2196f3;
  color: #fff;
}

.sun .organization__create-button {
  background-color: #f5f6fa;
  color: #060b27;
}

.sun .organization__create-button:hover {
  background-color: #060b27;
  color: #fff;
}

.organization__select {
  max-width: fit-content;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: #e9e7e7;
  background: #242630;
  outline: none;
  border: 1px solid #e9e7e7;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
}

.sun .organization__select {
  background: transparent;
  color: #060b27;
  border: 1px solid #e9e7e7;
}

.organization__select option {
  color: #fff;
  font-size: inherit;
  font-weight: inherit;
  padding: 10px 0;
}

.sun .organization__select option {
  color: #060b27;
}

.organization__select:focus {
  border: 1px solid #ff8630;
}
</style>
