<script>
import texts from '@/vendor/texts/texts.json';
import HomeView from './views/HomeView.vue';

export default {
  name: 'AppVue',
  components: { HomeView },

  mounted() {
    this.$store.commit('setPageTexts', texts['ru']);
  },
};
</script>

<template>
  <HomeView></HomeView>
</template>

<style></style>
