<script>
import { AgGridVue } from 'ag-grid-vue3'; // the AG Grid Vue Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-material.css'; // Optional theme CSS
import { csvToObj } from 'csv-to-js-parser';
import * as XLSX from 'xlsx/xlsx.mjs';
import { gridLocaleRu } from '@/utills/gridLocale';
import { fieldConfig } from '@/utills/tableConfig';

export default {
  name: 'DataTableVue',

  props: {
    tableInfo: Object,
    paginationMethod: Function,
    tableSize: Object,
    exportAllPages: Function,
    selectedFunc: Object,
  },

  components: { AgGridVue },

  mounted() {
    this.localeText = gridLocaleRu;
    this.initTableField();
    this.defaultColDef.flex = this.tableSize.flex;
  },

  computed: {
    tableData() {
      return this.tableInfo.tableData;
    },

    tableKey() {
      return this.tableInfo.tableKey;
    },
  },

  data: () => {
    return {
      localeText: {},
      gridApi: null,
      defaultColDef: {
        suppressMovable: false,
        filter: false,
        filterParams: {
          maxNumConditions: 1,
          filterOptions: [
            'contains',
            'notContains',
            'lessThan',
            'lessThanOrEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'equals',
            'notEqual',
            'inRange',
          ],

          buttons: ['apply', 'reset'],
          closeOnApply: true,
        },

        sortable: true,
        wrapHeaderText: true,
        wrapText: false,
        autoHeaderHeight: true,
        autoHeight: true,
      },
      gridOptions: {
        rowSelection: 'multiple',
        animateRows: true,
      },
      columnDefs: [],
    };
  },

  methods: {
    initTableField() {
      this.columnDefs = fieldConfig(this.tableKey);
    },

    onGridReady(params) {
      this.gridApi = params.api;
    },

    onBtnExportXlsx(saveAll) {
      const csv = this.gridApi.getDataAsCsv();
      let csvData = csvToObj(csv);
      const workbook = XLSX.utils.book_new();
      const workSheet = XLSX.utils.json_to_sheet(csvData);
      XLSX.utils.book_append_sheet(workbook, workSheet, 'Table');
      if (saveAll && saveAll.type !== 'click') {
        const workSheetPage = XLSX.utils.json_to_sheet(this.exportAllObjectArray());
        XLSX.utils.book_append_sheet(workbook, workSheetPage, 'Table page');
      }
      XLSX.writeFileXLSX(workbook, 'Wallet.xlsx');
    },

    onBtnExportXlsxAll() {
      this.exportAllPages();
    },

    exportAllObjectArray() {
      const keyDataObj = this.tableData[0];
      const keysDataArray = Object.keys(keyDataObj);
      const objectArray = [];
      keysDataArray.map((key) => {
        if (Array.isArray(keyDataObj[key])) {
          return this.tableData.map((item) => {
            item[key].map((obj) => {
              obj.id = item.id;
              return objectArray.push(obj);
            });
          });
        }
      });
      return objectArray;
    },

    nextPage() {
      this.paginationMethod(this.tableInfo.currentPage + 1, this.tableInfo.visibleRows);
    },

    prevPage() {
      this.paginationMethod(this.tableInfo.currentPage - 1, this.tableInfo.visibleRows);
    },
  },
};
</script>

<template>
  <AgGridVue
    class="ag-theme-material"
    :gridOptions="gridOptions"
    :defaultColDef="defaultColDef"
    :columnDefs="columnDefs"
    :rowData="tableData"
    :localeText="localeText"
    :isRowSelectable="
      (rowNode) => {
        return rowNode.data ? rowNode.data.status !== 'INACTIVE' : false;
      }
    "
    @grid-ready="onGridReady"
    :style="{ height: tableSize.height, width: tableSize.width }">
  </AgGridVue>
  <div class="table__functional">
    <div v-if="tableInfo.exportActive" class="table-export">
      <button
        :disabled="this.tableData.length <= 0"
        class="table-export-button"
        @click="onBtnExportXlsx">
        Экспорт текущей страницы
      </button>
      <button
        :disabled="this.tableData.length <= 0 || this.tableInfo.totalPages <= 1"
        class="table-export-button"
        @click="onBtnExportXlsxAll">
        Экспорт всех страниц
      </button>
    </div>
    <div v-if="selectedFunc?.func" class="table-export">
      <button class="table-export-button" @click="selectedFunc.func(gridApi.getSelectedRows())">
        {{ selectedFunc.text }}
      </button>
    </div>
    <div v-if="tableInfo.totalPages" class="table-pagination">
      <p class="table-pagination-text">Количество: {{ tableInfo.totalRows }}</p>
      <button
        @click="prevPage"
        :disabled="tableInfo.pageDisabled.first"
        type="button"
        class="table-pagination-button">
        <svg
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.75 19.5L8.25 12l7.5-7.5"></path>
        </svg>
      </button>
      <p class="table-pagination-text">
        {{ tableInfo.currentPage + 1 }} <span class="table-pagintaion-text_small">ИЗ</span
        >{{ tableInfo.totalPages }}
      </p>
      <button
        @click="nextPage"
        :disabled="tableInfo.pageDisabled.last"
        type="button"
        class="table-pagination-button">
        <svg
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5"></path>
        </svg>
      </button>
    </div>
  </div>
</template>

<style>
.span:has(.table-info) {
  position: absolute;
}

.table-info {
  width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.table-info svg {
  max-width: 30px;
  transition: 0.3s;
}

.table-info svg:hover {
  transform: scale(1.1);
}

.table__functional {
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.table-transaction {
  width: 100%;
  min-height: 40px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.table-transaction-button {
  width: 40%;
  height: 100%;
  min-height: 40px;
  background-image: url(@/assets/icons/pay.svg);
  background-color: #42b883;
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  transition: 0.3s;
  margin-left: 25px;
}

.status-wallet {
  font-size: 14px;
  font-weight: 500;
  color: #42b883;
}

.status-wallet_color_red {
  color: #f08080;
}

.table-pagination {
  max-width: 350px;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-left: auto;
}

.table-pagination-button {
  max-width: 20px;
  width: 20px;
  height: 20px;
  color: currentColor;
}

.table-pagination-text {
  display: flex;
  gap: 10px;
  font-size: 14px;
  font-weight: 600;
}

.table-pagination-button:disabled {
  color: gray;
}

.table-pagintaion-text_small {
}

.table-export {
  display: flex;
  gap: 20px;
}

.table-export-button {
  text-transform: capitalize;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  color: #e9e7e7;
  border: 1px solid #e9e7e7;
  border-radius: 5px;
  padding: 10px;
  margin: auto;
  transition: 0.3s;
}

.table-export-button:hover {
  background: #006eef;
  color: #fff;
}

.sun .table-export-button {
  color: #060b27;
  border: 1px solid #060b27;
}

.sun .table-export-button:hover {
  background-color: #060b27;
  color: #fff;
}

.table-export-button:disabled {
  opacity: 0.6;
}

.table-export-button:disabled:hover {
  background: none;
  color: #006eef;
}

.sun .table-export-button:disabled:hover {
  background: none;
  color: #060b27;
}

.more-table__button {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
  text-align: start;
  font-size: 12px;
  font-weight: 600;
  color: currentColor;
  cursor: pointer;
  transition: opacity 0.2s linear;
}

.more-table__button:hover {
  opacity: 0.7;
}

.more-table__button svg {
  width: 20px;
  height: 20px;
  transition: transform 0.4s ease-in-out;
}

.more-table__button:hover svg {
  transform: translate(5px, 0);
}

.table-link {
  color: currentColor;
  text-decoration: none;
  font-weight: 600;
}
</style>
