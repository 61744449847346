<script>
export default {
  name: 'HomeVue',

  data: () => {
    return {};
  },
};
</script>

<template>
  <section class="home">
    <div class="home__decoration"></div>
    <p class="home__title">
      <span class="home__title_purple">Pay</span><span class="home__title_green">Gate</span>
    </p>
    <p class="home__subtitle">Добро пожаловать в панель Администратора</p>
  </section>
</template>

<style>
.home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-top: 70px;
}
.home__decoration {
  max-width: 400px;
  width: 100%;
  background-image: url(@/assets/pg-logo.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding: 200px 0;
}
.home__title {
  font-size: 70px;
  font-weight: 600;
  line-height: 70px;
  text-align: center;
  letter-spacing: 2px;
  color: currentColor;
}

.home__subtitle {
  max-width: 610px;
  font-size: 42px;
  font-weight: 600;
  line-height: 52px;
  text-align: center;
  color: currentColor;
  padding-top: 30px;
}

.home__title_purple {
  color: #59499a;
  margin-right: 3px;
}
.home__title_green {
  color: #6fcf97;
}
</style>
