<script>
export default {
  name: 'PreloaderVue',

  props: { preloaderComplete: Boolean },

  data: () => {
    return {};
  },
};
</script>

<template>
  <svg width="70" height="70" viewBox="0 0 100 100">
    <polyline
      class="line-cornered stroke-still"
      points="0,0 100,0 100,100"
      stroke-width="5"
      fill="none"></polyline>
    <polyline
      class="line-cornered stroke-still"
      points="0,0 0,100 100,100"
      stroke-width="5"
      fill="none"></polyline>
    <polyline
      class="line-cornered stroke-animation"
      :class="{ 'stroke-complete-animation': preloaderComplete }"
      points="0,0 100,0 100,100"
      stroke-width="5"
      fill="none"></polyline>
    <polyline
      class="line-cornered stroke-animation"
      :class="{ 'stroke-complete-animation': preloaderComplete }"
      points="0,0 0,100 100,100"
      stroke-width="5"
      fill="none"></polyline>
  </svg>
  <p v-if="preloaderComplete" class="preloader-complete-text">Операция проведена успешно</p>
</template>

<style scoped>
.preloader-complete-text {
  font-size: 18px;
  font-weight: 600;
  color: #69c390;
  position: absolute;
  top: 550px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

svg {
  position: absolute;
  top: 415px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(45deg) scale(1);
  transform: translate(-50%, -50%) rotate(45deg) scale(1);
}

.stroke-still {
  stroke: #060b27;
}

.sun .stroke-still {
  stroke: #060b27;
}

.stroke-animation {
  -webkit-animation: stroke-spacing 1.2s ease-in, stroke-color 4.8s linear;
  animation: stroke-spacing 1.2s ease-in, stroke-color 4.8s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-delay: 0;
  animation-delay: 0;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

.stroke-complete-animation {
  animation: stroke-complete-spacing 2.4s ease-in, stroke-complete-color 4.8s linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-delay: 0;
  animation-delay: 0;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

@keyframes stroke-complete-color {
  0% {
    stroke: #69c390;
  }
  24% {
    stroke: #69c390;
  }
  25% {
    stroke: #69c390;
  }
  49% {
    stroke: #69c390;
  }
  50% {
    stroke: #69c390;
  }
  74% {
    stroke: #69c390;
  }
  75% {
    stroke: #69c390;
  }
  99% {
    stroke: #69c390;
  }
  100% {
    stroke: #69c390;
  }
}

@keyframes stroke-complete-spacing {
  0% {
    stroke-dasharray: 0 200;
  }
  45% {
    stroke-dashoffset: 0;
    stroke-dasharray: 200 200;
  }
}

@-webkit-keyframes stroke-spacing {
  0% {
    stroke-dasharray: 0 200;
  }
  45% {
    stroke-dashoffset: 0;
    stroke-dasharray: 200 200;
  }
  90% {
    stroke-dashoffset: -200;
    stroke-dasharray: 200 200;
  }
  100% {
    stroke-dashoffset: -200;
    stroke-dasharray: 200 200;
  }
}

@keyframes stroke-spacing {
  0% {
    stroke-dasharray: 0 200;
  }
  45% {
    stroke-dashoffset: 0;
    stroke-dasharray: 200 200;
  }
  90% {
    stroke-dashoffset: -200;
    stroke-dasharray: 200 200;
  }
  100% {
    stroke-dashoffset: -200;
    stroke-dasharray: 200 200;
  }
}

@-webkit-keyframes stroke-color {
  0% {
    stroke: #3498db;
  }
  24% {
    stroke: #3498db;
  }
  25% {
    stroke: #3498db;
  }
  49% {
    stroke: #3498db;
  }
  50% {
    stroke: #3498db;
  }
  74% {
    stroke: #3498db;
  }
  75% {
    stroke: #3498db;
  }
  99% {
    stroke: #3498db;
  }
}

@keyframes stroke-color {
  0% {
    stroke: #3498db;
  }
  24% {
    stroke: #3498db;
  }
  25% {
    stroke: #3498db;
  }
  49% {
    stroke: #3498db;
  }
  50% {
    stroke: #3498db;
  }
  74% {
    stroke: #3498db;
  }
  75% {
    stroke: #3498db;
  }
  99% {
    stroke: #3498db;
  }
}
</style>
